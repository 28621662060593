import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import '../KonkLand.css';

// Increase grid size for an even better game experience
const GRID_SIZE = 20; // Reduced from 25 to 20
const CELL_SIZE = 18; // Slightly smaller cells for better fit
const INITIAL_SNAKE = [
  { x: 10, y: 10 },
  { x: 9, y: 10 },
  { x: 8, y: 10 }
];
const INITIAL_DIRECTION = 'RIGHT';
const GAME_SPEED = 150;

// Add global styles to prevent tap highlight color
const globalStyles = `
  * {
    -webkit-tap-highlight-color: transparent !important;
  }
  
  button, a, input, div {
    -webkit-tap-highlight-color: transparent !important;
    tap-highlight-color: transparent !important;
    outline: none !important;
  }
`;

const SnakeGame = () => {
  const [snake, setSnake] = useState(INITIAL_SNAKE);
  const [food, setFood] = useState({ x: 15, y: 10 });
  const [direction, setDirection] = useState(INITIAL_DIRECTION);
  const [isGameOver, setIsGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [highScore, setHighScore] = useState(() => {
    const saved = localStorage.getItem('snakeHighScore');
    return saved ? parseInt(saved) : 0;
  });

  // Generate random food position
  const generateFood = useCallback(() => {
    const newFood = {
      x: Math.floor(Math.random() * GRID_SIZE),
      y: Math.floor(Math.random() * GRID_SIZE)
    };
    if (snake.some(segment => segment.x === newFood.x && segment.y === newFood.y)) {
      return generateFood();
    }
    return newFood;
  }, [snake]);

  // Handle touch controls
  const handleTouchStart = (e) => {
    if (isGameOver) return;
    
    const touch = e.touches[0];
    const touchStartX = touch.clientX;
    const touchStartY = touch.clientY;

    const handleTouchEnd = (e) => {
      const touch = e.changedTouches[0];
      const deltaX = touch.clientX - touchStartX;
      const deltaY = touch.clientY - touchStartY;

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        // Horizontal swipe
        if (deltaX > 0 && direction !== 'LEFT') {
          setDirection('RIGHT');
        } else if (deltaX < 0 && direction !== 'RIGHT') {
          setDirection('LEFT');
        }
      } else {
        // Vertical swipe
        if (deltaY > 0 && direction !== 'UP') {
          setDirection('DOWN');
        } else if (deltaY < 0 && direction !== 'DOWN') {
          setDirection('UP');
        }
      }
    };

    document.addEventListener('touchend', handleTouchEnd, { once: true });
  };

  // Game loop
  useEffect(() => {
    if (isGameOver || isPaused) return;

    const moveSnake = () => {
      const newSnake = [...snake];
      const head = { ...newSnake[0] };

      switch (direction) {
        case 'UP':
          head.y -= 1;
          break;
        case 'DOWN':
          head.y += 1;
          break;
        case 'LEFT':
          head.x -= 1;
          break;
        case 'RIGHT':
          head.x += 1;
          break;
        default:
          break;
      }

      // Check for collisions
      if (
        head.x < 0 ||
        head.x >= GRID_SIZE ||
        head.y < 0 ||
        head.y >= GRID_SIZE ||
        newSnake.some(segment => segment.x === head.x && segment.y === head.y)
      ) {
        setIsGameOver(true);
        if (score > highScore) {
          setHighScore(score);
          localStorage.setItem('snakeHighScore', score.toString());
        }
        return;
      }

      newSnake.unshift(head);

      if (head.x === food.x && head.y === food.y) {
        setScore(prev => prev + 1);
        setFood(generateFood());
      } else {
        newSnake.pop();
      }

      setSnake(newSnake);
    };

    const gameInterval = setInterval(moveSnake, GAME_SPEED);
    return () => clearInterval(gameInterval);
  }, [snake, direction, food, isGameOver, isPaused, generateFood, score, highScore]);

  // Reset game
  const resetGame = () => {
    setSnake(INITIAL_SNAKE);
    setDirection(INITIAL_DIRECTION);
    setFood(generateFood());
    setIsGameOver(false);
    setScore(0);
    setIsPaused(false);
  };

  // Handle keyboard controls
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isGameOver) return;
      
      switch (e.key) {
        case 'ArrowUp':
          if (direction !== 'DOWN') setDirection('UP');
          break;
        case 'ArrowDown':
          if (direction !== 'UP') setDirection('DOWN');
          break;
        case 'ArrowLeft':
          if (direction !== 'RIGHT') setDirection('LEFT');
          break;
        case 'ArrowRight':
          if (direction !== 'LEFT') setDirection('RIGHT');
          break;
        case ' ':
          setIsPaused(prev => !prev);
          break;
        default:
          break;
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [direction, isGameOver]);

  // Helper function to determine snake head rotation
  const getSnakeHeadRotation = () => {
    switch (direction) {
      case 'UP': return 'rotate(-90deg)';
      case 'DOWN': return 'rotate(90deg)';
      case 'LEFT': return 'rotate(180deg)';
      case 'RIGHT': return 'rotate(0deg)';
      default: return 'rotate(0deg)';
    }
  };

  return (
    <div className="snake-game-page">
      <div className="snake-game-header">
        <Link to="/" className="back-link">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 12H5" stroke="#11140C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 19L5 12L12 5" stroke="#11140C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </Link>
        <h3>Snake Game</h3>
        <div style={{ width: 24 }}></div> {/* Empty div for layout balance */}
      </div>
      
      <div className="snake-game-container">
        <div className="snake-game-info">
          <div style={{ color: '#ccef22', fontFamily: 'Chakra Petch', fontSize: '18px' }}>
            Score: {score}
          </div>
          <div style={{ color: '#ccef22', fontFamily: 'Chakra Petch', fontSize: '18px' }}>
            Best: {highScore}
          </div>
        </div>
        
        <div
          className="snake-game-board"
          style={{
            position: 'relative',
            width: `${GRID_SIZE * CELL_SIZE}px`,
            height: `${GRID_SIZE * CELL_SIZE}px`,
            border: '2px solid rgba(204, 239, 34, 0.3)',
            borderRadius: '8px',
            backgroundColor: '#11140C',
            overflow: 'hidden',
            touchAction: 'none',
            margin: '0 auto',
            maxWidth: '95vw',
            maxHeight: '95vw',
          }}
          onTouchStart={handleTouchStart}
        >
          {/* Snake Segments (excluding head) */}
          {snake.slice(1).map((segment, index) => (
            <div
              key={index}
              style={{
                position: 'absolute',
                top: `${segment.y * CELL_SIZE}px`,
                left: `${segment.x * CELL_SIZE}px`,
                width: `${CELL_SIZE - 2}px`,
                height: `${CELL_SIZE - 2}px`,
                backgroundColor: 'rgba(204, 239, 34, 0.7)',
                borderRadius: '2px',
                zIndex: 1,
              }}
            />
          ))}
          
          {/* Snake Head with Eyes */}
          {snake.length > 0 && (
            <div
              style={{
                position: 'absolute',
                top: `${snake[0].y * CELL_SIZE}px`,
                left: `${snake[0].x * CELL_SIZE}px`,
                width: `${CELL_SIZE - 2}px`,
                height: `${CELL_SIZE - 2}px`,
                backgroundColor: '#ccef22',
                borderRadius: '3px',
                zIndex: 2,
                transform: getSnakeHeadRotation(),
              }}
            >
              {/* Left Eye */}
              <div style={{
                position: 'absolute',
                top: '3px',
                left: '3px',
                width: '4px',
                height: '4px',
                backgroundColor: '#11140C',
                borderRadius: '50%',
              }} />
              
              {/* Right Eye */}
              <div style={{
                position: 'absolute',
                top: '3px',
                right: '3px',
                width: '4px',
                height: '4px',
                backgroundColor: '#11140C',
                borderRadius: '50%',
              }} />
            </div>
          )}
          
          {/* Food */}
          <div
            style={{
              position: 'absolute',
              top: `${food.y * CELL_SIZE}px`,
              left: `${food.x * CELL_SIZE}px`,
              width: `${CELL_SIZE - 2}px`,
              height: `${CELL_SIZE - 2}px`,
              backgroundColor: '#ff6b6b',
              borderRadius: '50%',
              zIndex: 1,
            }}
          />
          
          {/* Game Over Overlay */}
          {isGameOver && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 2,
              }}
            >
              <div style={{ color: '#ccef22', fontSize: '24px', marginBottom: '15px', fontFamily: 'Chakra Petch' }}>
                Game Over
              </div>
              <div style={{ color: '#fff', fontSize: '16px', marginBottom: '20px', fontFamily: 'Chakra Petch' }}>
                Final Score: {score}
              </div>
              <button
                onClick={resetGame}
                style={{
                  backgroundColor: '#ccef22',
                  color: '#11140C',
                  border: 'none',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontFamily: 'Chakra Petch',
                  fontWeight: 'bold',
                }}
              >
                Play Again
              </button>
            </div>
          )}
          
          {/* Pause Overlay */}
          {isPaused && !isGameOver && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 2,
              }}
            >
              <div style={{ color: '#ccef22', fontSize: '24px', fontFamily: 'Chakra Petch' }}>
                Paused
              </div>
            </div>
          )}
        </div>
        
        <div className="snake-game-controls">
          <button
            onClick={() => setIsPaused(!isPaused)}
            style={{
              backgroundColor: '#ccef22',
              color: '#11140C',
              border: 'none',
              padding: '10px 15px',
              borderRadius: '5px',
              margin: '15px 5px',
              cursor: 'pointer',
              fontFamily: 'Chakra Petch',
              fontWeight: 'bold',
            }}
          >
            {isPaused ? 'Resume' : 'Pause'}
          </button>
          
          <button
            onClick={resetGame}
            style={{
              backgroundColor: '#11140C',
              color: '#ccef22',
              border: '1px solid #ccef22',
              padding: '10px 15px',
              borderRadius: '5px',
              margin: '15px 5px',
              cursor: 'pointer',
              fontFamily: 'Chakra Petch',
              fontWeight: 'bold',
            }}
          >
            Restart
          </button>
        </div>
        
        <div className="snake-game-instructions">
          <p style={{ color: '#9BA885', fontSize: '14px', textAlign: 'center', fontFamily: 'Chakra Petch' }}>
            Use arrow keys or swipe to control the snake.
            <br />
            Press space to pause/resume.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SnakeGame; 