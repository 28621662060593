import React, { useState, useRef, useEffect } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useContractRead } from 'wagmi';
import './KonkRun.css';

const KONK_ADDRESS = '0x2b9712190Ce9570C1CA860D55B1623Bd88BF96fF';
const STKONK_ADDRESS = '0x90fC7ABB6734dF30b9dC726f6f92EefD9c3c7Bb2';
const REQUIRED_AMOUNT = 10000;
const DOUBLE_TAP_DELAY = 300;
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

// Game Constants
const INITIAL_VELOCITY = 8;
const HIGH_JUMP_VELOCITY = 11;
const GRAVITY = 0.5;
const GROUND_LEVEL = 0;
const ANIMATION_INTERVAL = 20;
const SCORE_UPDATE_INTERVAL = 100;
const SCORE_INCREMENT = 10;
const GAME_WIDTH = 800;
const GAME_HEIGHT = 200;

const LEVELS = [
  { level: 1, speed: 5, numObstacles: 1 },
  { level: 2, speed: 6, numObstacles: 2 },
  { level: 3, speed: 7, numObstacles: 2 },
  { level: 4, speed: 8, numObstacles: 2 },
  { level: 5, speed: 9, numObstacles: 3 },
  { level: 6, speed: 10, numObstacles: 3 },
  { level: 7, speed: 11, numObstacles: 3 },
  { level: 8, speed: 12, numObstacles: 3 },
  { level: 9, speed: 13, numObstacles: 3 },
  { level: 10, speed: 14, numObstacles: 3 }
];

const KonkRunKONKABI = [
  {
    "inputs": [{ "name": "account", "type": "address" }],
    "name": "balanceOf",
    "outputs": [{ "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  }
];

const KonkRunSTKONKABI = [
  {
    "inputs": [{ "name": "account", "type": "address" }],
    "name": "balanceOf",
    "outputs": [{ "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  }
];

const KonkRun = () => {
  // Game state
  const gameStateRef = useRef({
    isJumping: false,
    canJump: true,
    position: GROUND_LEVEL,
    velocity: 0,
    score: 0,
    jumpCount: 0,
    frameCount: 0,
    lastFrameTime: 0,
    lastObstacleTime: 0,
    obstaclesPassed: 0,
    currentLevel: 1,
    obstacleSpeed: LEVELS[0].speed
  });

  // React state
  const [score, setScore] = useState(0);
  const [jumpPoints, setJumpPoints] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [position, setPosition] = useState(GROUND_LEVEL);
  const [obstacles, setObstacles] = useState([]);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [lastTapTime, setLastTapTime] = useState(0);
  const [debugMode, setDebugMode] = useState(false);

  // Refs
  const playerRef = useRef(null);
  const gameContainerRef = useRef(null);
  const isPlayingRef = useRef(false);
  const jumpIntervalRef = useRef(null);
  const scoreIntervalRef = useRef(null);
  const gameLoopRef = useRef(null);

  const { address } = useAccount();

  // Contract reads
  const { data: konkBalance } = useContractRead({
    address: KONK_ADDRESS,
    abi: KonkRunKONKABI,
    functionName: 'balanceOf',
    args: [address],
    enabled: !!address,
  });

  const { data: stKonkBalance } = useContractRead({
    address: STKONK_ADDRESS,
    abi: KonkRunSTKONKABI,
    functionName: 'balanceOf',
    args: [address],
    enabled: !!address,
  });

  const debugLog = (section, data) => {
    if (debugMode) {
      console.log(`%c[${section}]`, 'color: yellow; font-weight: bold', data);
    }
  };

  const hasRequiredBalance = () => {
    if (!konkBalance || !stKonkBalance) return false;
    const konkAmount = Number(konkBalance) / 1e18;
    const stKonkAmount = Number(stKonkBalance) / 1e18;
    return konkAmount >= REQUIRED_AMOUNT || stKonkAmount >= REQUIRED_AMOUNT;
  };

  const playJumpSound = () => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    const gainNode = audioContext.createGain();

    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(100, audioContext.currentTime);
    oscillator.frequency.exponentialRampToValueAtTime(700, audioContext.currentTime + 0.2);
    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);

    gainNode.gain.setValueAtTime(0.5, audioContext.currentTime);
    oscillator.start();
    gainNode.gain.linearRampToValueAtTime(0, audioContext.currentTime + 0.2 - 0.01);
    oscillator.stop(audioContext.currentTime + 0.2);
  };

  const playBuzzSound = () => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    const gainNode = audioContext.createGain();

    oscillator.type = 'sawtooth';
    oscillator.frequency.setValueAtTime(100, audioContext.currentTime);
    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);

    gainNode.gain.setValueAtTime(0.5, audioContext.currentTime);
    oscillator.start();
    oscillator.stop(audioContext.currentTime + 0.2);
  };

  const checkCollisions = () => {
    if (!playerRef.current || !isPlayingRef.current) return;

    const playerRect = playerRef.current.getBoundingClientRect();
    const gameRect = gameContainerRef.current.getBoundingClientRect();

    // Calculate relative position to game container
    const playerBox = {
      left: playerRect.left - gameRect.left,
      top: playerRect.top - gameRect.top,
      width: playerRect.width,
      height: playerRect.height
    };

    obstacles.forEach(obstacle => {
      const obstacleElement = document.getElementById(`obstacle-${obstacle.id}`);
      if (!obstacleElement) return;

      const obstacleRect = obstacleElement.getBoundingClientRect();
      const obstacleBox = {
        left: obstacleRect.left - gameRect.left,
        top: obstacleRect.top - gameRect.top,
        width: obstacleRect.width,
        height: obstacleRect.height
      };

      // Using the vanilla collision check
      if (
        playerBox.left < (obstacleBox.left + obstacleBox.width) &&
        (playerBox.left + playerBox.width) > obstacleBox.left &&
        playerBox.top < (obstacleBox.top + obstacleBox.height) &&
        (playerBox.height + playerBox.top) > obstacleBox.top
      ) {
        console.log('COLLISION DETECTED', {
          player: playerBox,
          obstacle: obstacleBox
        });
        endGame();
      }
    });
  };

  const jump = (highJump = false) => {
    if (gameStateRef.current.isJumping || !gameStateRef.current.canJump || !isPlayingRef.current) {
      return;
    }

    gameStateRef.current.isJumping = true;
    gameStateRef.current.canJump = false;
    gameStateRef.current.velocity = highJump ? HIGH_JUMP_VELOCITY : INITIAL_VELOCITY;
    
    // Add jump points when jumping
    setJumpPoints(prev => prev + SCORE_INCREMENT);

    if (jumpIntervalRef.current) {
      clearInterval(jumpIntervalRef.current);
    }

    const updateJump = () => {
      if (!isPlayingRef.current) return;

      const state = gameStateRef.current;

      if (state.velocity <= 0 && state.position <= GROUND_LEVEL) {
        clearInterval(jumpIntervalRef.current);
        state.isJumping = false;
        state.canJump = true;
        state.position = GROUND_LEVEL;
        state.velocity = 0;
        setPosition(GROUND_LEVEL);
      } else {
        state.position += state.velocity;
        state.velocity -= GRAVITY;

        if (state.position < GROUND_LEVEL) {
          state.position = GROUND_LEVEL;
        }

        setPosition(state.position);
      }
    };

    jumpIntervalRef.current = setInterval(updateJump, ANIMATION_INTERVAL);
    playJumpSound();
  };

  const createObstacle = () => {
    if (!isPlayingRef.current) return;

    const currentTime = performance.now();
    if (currentTime - gameStateRef.current.lastObstacleTime < 1000) {
      return;
    }

    if (obstacles.length >= LEVELS[currentLevel - 1].numObstacles) {
      return;
    }

    const newObstacle = {
      id: Date.now() + Math.random(),
      position: { x: GAME_WIDTH, y: 0 },
      isJumping: Math.random() > 0.5,
      isGoingUp: true
    };

    debugLog('NEW_OBSTACLE', newObstacle);
    gameStateRef.current.lastObstacleTime = currentTime;
    setObstacles(prev => [...prev, newObstacle]);
  };

  const moveObstacles = () => {
    if (!isPlayingRef.current) return;

    setObstacles(prevObstacles => {
      return prevObstacles.map(obstacle => {
        const currentSpeed = LEVELS[currentLevel - 1].speed;
        const newX = obstacle.position.x - currentSpeed;
        let newY = obstacle.position.y;

        if (obstacle.isJumping) {
          if (obstacle.isGoingUp) {
            newY = Math.min(newY + 2, 80);
            if (newY >= 80) {
              obstacle.isGoingUp = false;
            }
          } else {
            newY = Math.max(newY - 2, 0);
            if (newY <= 0) {
              obstacle.isGoingUp = true;
            }
          }
        }

        debugLog('OBSTACLE_MOVE', {
          id: obstacle.id,
          oldX: obstacle.position.x,
          newX,
          y: newY
        });

        return {
          ...obstacle,
          position: { x: newX, y: newY }
        };
      }).filter(obstacle => {
        if (obstacle.position.x < -30) {
          gameStateRef.current.obstaclesPassed++;
          
          // Update level every 10 obstacles
          if (gameStateRef.current.obstaclesPassed % 10 === 0) {
            const newLevel = Math.min(currentLevel + 1, LEVELS.length);
            setCurrentLevel(newLevel);
            debugLog('LEVEL_UP', { newLevel, speed: LEVELS[newLevel - 1].speed });
          }

          setTimeout(() => createObstacle(), 1000);
          return false;
        }
        return true;
      });
    });
  };

  const gameLoop = () => {
    if (!isPlayingRef.current) return;

    moveObstacles();
    checkCollisions();
    gameLoopRef.current = requestAnimationFrame(gameLoop);
  };

  const startGame = async () => {
    if (!address) {
      alert('Please connect your wallet to play!');
      return;
    }

    setVerificationStatus('Checking balance...');
    
    try {
      if (hasRequiredBalance()) {
        setVerificationStatus('');
        
        // Reset game state
        const state = gameStateRef.current;
        state.score = 0;
        state.jumpCount = 0;
        state.position = GROUND_LEVEL;
        state.velocity = 0;
        state.isJumping = false;
        state.canJump = true;
        state.lastObstacleTime = 0;
        state.obstaclesPassed = 0;
        state.currentLevel = 1;
        state.obstacleSpeed = LEVELS[0].speed;

        setScore(0);
        setJumpPoints(0);
        setObstacles([]);
        setCurrentLevel(1);
        setPosition(GROUND_LEVEL);

        isPlayingRef.current = true;
        setIsPlaying(true);

        // Start continuous score increment
        if (scoreIntervalRef.current) {
          clearInterval(scoreIntervalRef.current);
        }
        scoreIntervalRef.current = setInterval(() => {
          if (isPlayingRef.current) {
            setScore(prev => prev + 10);
          }
        }, SCORE_UPDATE_INTERVAL);

        debugLog('GAME_START', 'Game initialized');
        setTimeout(createObstacle, 1000);
        gameLoopRef.current = requestAnimationFrame(gameLoop);

      } else {
        alert('You need at least 10,000 KONK or stKONK to play!');
        setVerificationStatus('');
      }
    } catch (error) {
      console.error('Balance check failed:', error);
      alert('Failed to verify balance. Please try again.');
      setVerificationStatus('');
    }
  };

  const endGame = () => {
    debugLog('GAME_END', 'Ending game');
    isPlayingRef.current = false;
    setIsPlaying(false);

    if (jumpIntervalRef.current) {
      clearInterval(jumpIntervalRef.current);
      jumpIntervalRef.current = null;
    }

    if (scoreIntervalRef.current) {
      clearInterval(scoreIntervalRef.current);
      scoreIntervalRef.current = null;
    }

    if (gameLoopRef.current) {
      cancelAnimationFrame(gameLoopRef.current);
      gameLoopRef.current = null;
    }

    playBuzzSound();
    setTimeout(() => {
      alert(`Game Over!\nScore: ${score}\nJump Points: ${jumpPoints}`);
    }, 100);
  };

  const handleKeyDown = (e) => {
    if (!isPlayingRef.current || e.repeat) return;
    if (e.code === 'Space') {
      e.preventDefault();
      jump(e.shiftKey);
      debugLog('INPUT', { type: 'keydown', key: 'Space', shiftKey: e.shiftKey });
    }
  };

  const handleTouch = (e) => {
    if (!isPlayingRef.current) return;
    e.preventDefault();
    
    const currentTime = new Date().getTime();
    const tapTimeDiff = currentTime - lastTapTime;
    
    debugLog('INPUT', { 
      type: 'touch', 
      timeDiff: tapTimeDiff,
      isDoubleTap: tapTimeDiff < DOUBLE_TAP_DELAY 
    });

    if (tapTimeDiff < DOUBLE_TAP_DELAY) {
      jump(true);
      setLastTapTime(0);
    } else {
      jump(false);
      setLastTapTime(currentTime);
    }
  };

  // Debug effect
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'd') {
        setDebugMode(prev => {
          const newValue = !prev;
          console.log('%cDEBUG MODE:', 'color: green; font-weight: bold', newValue ? 'ON' : 'OFF');
          return newValue;
        });
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, []);

  // Game cleanup effect
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('touchstart', handleTouch);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('touchstart', handleTouch);
      
      debugLog('CLEANUP', 'Cleaning up game resources');
      isPlayingRef.current = false;
      
      if (jumpIntervalRef.current) {
        clearInterval(jumpIntervalRef.current);
      }
      if (scoreIntervalRef.current) {
        clearInterval(scoreIntervalRef.current);
      }
      if (gameLoopRef.current) {
        cancelAnimationFrame(gameLoopRef.current);
      }
    };
  }, [lastTapTime]);

  return (
    <div className="game-page" style={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg_image.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <div className="rotate-prompt">
        <h2>Please Rotate Your Device</h2>
        <p>This game is best played in landscape mode</p>
      </div>

      <div className="game-header">
        <ConnectButton />
      </div>
      
      <div className="game-wrapper">
        <div 
          id="gameContainer" 
          ref={gameContainerRef}
          style={{ 
            backgroundImage: 'url("/images/bg.gif")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            overflow: 'hidden',
            width: `${GAME_WIDTH}px`,
            height: `${GAME_HEIGHT}px`,
            border: '6px solid rgb(0, 0, 0)'
          }}
        >
          {/* Player Character */}
          <div 
            id="playerCharacter" 
            ref={playerRef}
            data-debug="player"
            style={{ 
              bottom: `${position}px`,
              backgroundImage: 'url("/images/character.gif")',
              position: 'absolute',
              left: '50px',
              width: '50px',
              height: '50px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              zIndex: 2,
              transform: `translateY(-${position}px)`
            }}
          >
            {debugMode && (
              <div 
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: '2px solid red',
                  backgroundColor: 'rgba(255,0,0,0.2)',
                  pointerEvents: 'none'
                }}
              />
            )}
          </div>
          
          {/* Obstacles */}
          {obstacles.map(obstacle => (
            <div
              key={obstacle.id}
              id={`obstacle-${obstacle.id}`}
              data-debug={`obstacle-${obstacle.id}`}
              style={{
                position: 'absolute',
                left: `${obstacle.position.x}px`,
                bottom: `${obstacle.position.y}px`,
                width: '30px',
                height: '30px',
                backgroundImage: 'url("/images/obstacle.png")',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                zIndex: 2,
                transform: `translateY(-${obstacle.position.y}px)`
              }}
            >
              {debugMode && (
                <div 
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: '2px solid blue',
                    backgroundColor: 'rgba(0,0,255,0.2)',
                    pointerEvents: 'none'
                  }}
                />
              )}
            </div>
          ))}
          
          {/* Controls and Play Button */}
          {!isPlaying && score === 0 && (
            <>
              <div id="controls" style={{
                position: 'absolute',
                top: '30%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                background: 'rgba(0, 0, 0, 0.7)',
                padding: '15px',
                borderRadius: '10px',
                color: '#FEA304',
                zIndex: 10,
                minWidth: '100px'
              }}>
                <h2 style={{ margin: '0 0 2px 0', fontSize: '14px', textTransform: 'uppercase' }}>
                  CONTROLS:
                </h2>
                {isMobile ? (
                  <>
                    <p style={{ color: 'white', margin: '5px 0', fontSize: '12px' }}>Single Tap: Jump</p>
                    <p style={{ color: 'white', margin: '5px 0', fontSize: '12px' }}>Double Tap: High Jump</p>
                  </>
                ) : (
                  <>
                    <p style={{ color: 'white', margin: '5px 0', fontSize: '12px' }}>Space Bar: Jump</p>
                    <p style={{ color: 'white', margin: '5px 0', fontSize: '12px' }}>Shift + Space: High Jump</p>
                    {debugMode && <p style={{ color: '#00ff00', margin: '5px 0', fontSize: '12px' }}>Debug Mode: ON</p>}
                  </>
                )}
              </div>
              <img 
                src="/images/play_button.png" 
                alt="Play" 
                onClick={startGame}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '300px',
                  height: '100px',
                  cursor: 'pointer',
                  zIndex: 20
                }}
              />
            </>
          )}

          {/* Restart Button */}
          {!isPlaying && score > 0 && (
            <img 
              src="/images/restart_button.png" 
              alt="Restart" 
              onClick={startGame}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '300px',
                height: '100px',
                cursor: 'pointer',
                zIndex: 20
              }}
            />
          )}
          
          {/* Score Container */}
          {isPlaying && (
            <div id="scoreContainer" style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
              zIndex: 3
            }}>
              <div className="score" style={{
                fontSize: '24px',
                color: 'white',
                fontWeight: 'bold',
                background: 'rgba(0, 0, 0, 0.7)',
                padding: '5px 10px',
                borderRadius: '5px'
              }}>
                Score: <span>{score}</span>
              </div>
              <div className="jumpPoints" style={{
                fontSize: '24px',
                color: 'white',
                fontWeight: 'bold',
                background: 'rgba(0, 0, 0, 0.7)',
                padding: '5px 10px',
                borderRadius: '5px'
              }}>
                Jump Points: <span>{jumpPoints}</span>
              </div>
              <div className="level" style={{
                fontSize: '24px',
                color: 'white',
                fontWeight: 'bold',
                background: 'rgba(0, 0, 0, 0.7)',
                padding: '5px 10px',
                borderRadius: '5px'
              }}>
                Level: {currentLevel}
              </div>
            </div>
          )}
          
          {/* Verification Status */}
          {verificationStatus && (
            <div id="verificationStatus" style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              background: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              padding: '20px',
              borderRadius: '5px',
              textAlign: 'center',
              zIndex: 30
            }}>
              <div className="loader"></div>
              <div id="verificationMessage">{verificationStatus}</div>
            </div>
          )}

          {/* Debug Mode Indicator */}
          {debugMode && (
            <div style={{
              position: 'absolute',
              top: '10px',
              left: '10px',
              background: 'rgba(0, 0, 0, 0.7)',
              color: '#00ff00',
              padding: '5px',
              borderRadius: '5px',
              fontSize: '12px',
              zIndex: 100
            }}>
              Debug Mode: ON
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KonkRun;