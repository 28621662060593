import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useContractRead } from 'wagmi';
import { parseEther, formatEther } from 'viem';
import { useTransaction } from '../hooks/useTransaction';
import { useTransactionStatus } from '../hooks/useTransactionStatus';
import { useNotifications } from '../hooks/useNotifications';
import { useWallet } from '../WalletContext';
import { Home } from 'lucide-react';
import './KONKStaking.css';

const KONK_ADDRESS = '0x2b9712190ce9570c1ca860d55b1623bd88bf96ff';
const STAKING_ADDRESS = '0x90fC7ABB6734dF30b9dC726f6f92EefD9c3c7Bb2';

const ERC20_ABI = [
  {
    inputs: [{ internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },

  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" }
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  }
];

const VAULT_ABI = [
  {
    inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }],
    name: "deposit",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }, { internalType: "address", name: "owner", type: "address" }],
    name: "withdraw",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "totalAssets",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  }
];

function KONKStaking() {
    const { walletAddress, isConnected } = useWallet();
    const [amount, setAmount] = useState('');
    const [mode, setMode] = useState('stake');
    const [apy, setApy] = useState(0);
    const [rewards, setRewards] = useState(0);
    const [currentAction, setCurrentAction] = useState('');
    const [isApproved, setIsApproved] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
  
    const { sendTransaction, txHash, isSuccess, isError, isPending, receipt, resetTxHash } = useTransaction();
    const { notification, showNotification } = useNotifications();


  const { data: konkBalance, refetch: refetchKonkBalance } = useContractRead({
    address: KONK_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'balanceOf',
    args: [walletAddress || '0x'],
    enabled: !!walletAddress,
    watch: true,
  });

  const { data: stakedBalance, refetch: refetchStakedBalance } = useContractRead({
    address: STAKING_ADDRESS,
    abi: VAULT_ABI,
    functionName: 'balanceOf',
    args: [walletAddress || '0x'],
    enabled: !!walletAddress,
    watch: true,
  });

  const { data: totalStaked, refetch: refetchTotalStaked } = useContractRead({
    address: STAKING_ADDRESS,
    abi: VAULT_ABI,
    functionName: 'totalAssets',
    watch: true,
  });

  const { data: totalShares, refetch: refetchTotalShares } = useContractRead({
    address: STAKING_ADDRESS,
    abi: VAULT_ABI,
    functionName: 'totalSupply',
    watch: true,
  });

  const { data: allowance } = useContractRead({
    address: KONK_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'allowance',
    args: [walletAddress || '0x', STAKING_ADDRESS],
    enabled: !!walletAddress,
    watch: true,
  });

  const refreshAllStats = useCallback(async () => {
    await Promise.all([
      refetchKonkBalance(),
      refetchStakedBalance(),
      refetchTotalStaked(),
      refetchTotalShares(),
    ]);
    setRefreshTrigger(prev => prev + 1);
  }, [refetchKonkBalance, refetchStakedBalance, refetchTotalStaked, refetchTotalShares]);

  useEffect(() => {
    if (allowance && amount) {
      try {
        const amountBigInt = parseEther(amount);
        setIsApproved(allowance >= amountBigInt);
      } catch (error) {
        setIsApproved(false);
      }
    } else {
      setIsApproved(false);
    }
  }, [allowance, amount]);

  useEffect(() => {
    if (totalStaked && totalShares && stakedBalance) {
      const totalAssets = Number(formatEther(totalStaked));
      const totalSharesAmount = Number(formatEther(totalShares));
      const userShares = Number(formatEther(stakedBalance));
      
      if (totalSharesAmount > 0) {
        const currentSharePrice = totalAssets / totalSharesAmount;
        const initialSharePrice = 1;
        
        const rewardsAmount = userShares * (currentSharePrice - initialSharePrice);
        const priceChange = ((currentSharePrice / initialSharePrice) - 1);
        const annualizedReturn = priceChange * 100;
        
        setRewards(rewardsAmount > 0 ? rewardsAmount : 0);
        setApy(annualizedReturn > 0 ? annualizedReturn : 0);
      }
    }
  }, [totalStaked, totalShares, stakedBalance, refreshTrigger]); // Added refreshTrigger to dependencies

  const handlePercentageClick = useCallback((percentage) => {
    const balance = mode === 'stake' ? konkBalance : stakedBalance;
    
    if (balance) {
      // Basic percentage calculation for non-max cases
      if (mode === 'unstake' && percentage === 100 && totalStaked && totalShares && stakedBalance) {
        try {
          // High-precision calculation using string representations
          const totalAssetsStr = formatEther(totalStaked);
          const totalSharesStr = formatEther(totalShares);
          const userSharesStr = formatEther(stakedBalance);
          
          // Parse with high precision
          const totalAssetsNum = parseFloat(totalAssetsStr);
          const totalSharesNum = parseFloat(totalSharesStr);
          const userSharesNum = parseFloat(userSharesStr);
          
          if (totalSharesNum > 0) {
            // Calculate the exact share price with full precision
            const sharePrice = totalAssetsNum / totalSharesNum;
            let redeemableAmount = userSharesNum * sharePrice;
            
            // Apply a tiny safety buffer (0.01%) to prevent potential rounding issues
            // This ensures the transaction won't fail due to slight calculation differences
            redeemableAmount = redeemableAmount * 0.9999;
            
            // Set the amount with full precision
            setAmount(redeemableAmount.toString());
            return;
          }
        } catch (error) {
          console.error("Error calculating redeemable amount:", error);
        }
      }
      
      // Default behavior for other percentages or fallback
      const value = (Number(formatEther(balance)) * percentage) / 100;
      setAmount(value.toString());
    }
  }, [mode, konkBalance, stakedBalance, totalStaked, totalShares]);

  const handleApproveAndStake = async () => {
    if (!isApproved) {
        try {
            setCurrentAction('approve');
            await sendTransaction({
                address: KONK_ADDRESS,
                abi: ERC20_ABI,
                functionName: 'approve',
                args: [STAKING_ADDRESS, parseEther(amount)],
            });
            setIsApproved(true);
        } catch (error) {
            console.error('Approval error:', error);
            showNotification({ message: 'Approval failed' });
            setCurrentAction('');
        }
    } else {
        try {
            setCurrentAction('stake');
            await sendTransaction({
                address: STAKING_ADDRESS,
                abi: VAULT_ABI,
                functionName: 'deposit',
                args: [parseEther(amount), walletAddress],
            });
        } catch (error) {
            console.error('Stake error:', error);
            showNotification({ message: 'Stake failed' });
            setCurrentAction('');
        }
    }
};

  const handleUnstake = async () => {
    try {
      setCurrentAction('unstake');
      await sendTransaction({
        address: STAKING_ADDRESS,
        abi: VAULT_ABI,
        functionName: 'withdraw',
        args: [parseEther(amount), walletAddress, walletAddress],
      });
    } catch (error) {
      console.error('Unstake error:', error);
      showNotification({ message: 'Unstake failed' });
      setCurrentAction('');
    }
  };

  useTransactionStatus({
    txHash,
    isPending,
    isSuccess,
    isError,
    receipt,
    showNotification,
    onSuccess: async (receipt) => {
        // Clear input and reset states after successful stake/unstake
        if (currentAction === 'stake' || currentAction === 'unstake') {
            setAmount('');
            await refreshAllStats();
        } else if (currentAction === 'approve') {
            // Just refresh stats after approval
            await refreshAllStats();
        }
        setCurrentAction('');
        resetTxHash();
    },
    onError: () => {
        setCurrentAction('');
        resetTxHash();
    }
});

  return (
    <div className="staking-container">
      <div className="staking-header">
        <Link to="/" className="home-link">
          <Home size={24} />
        </Link>
        <div className="header-content">
          <h1><span>KONK</span> <span>STAKING</span></h1>
          <ConnectButton />
        </div>
      </div>

      <p className="subtitle">
        Stake your KONK tokens to earn more KONK. Your returns compound automatically, 
        no claiming required.
      </p>

      <div className="stats-container"style={{ WebkitTapHighlightColor: 'transparent' }}>
        <div className="stat-box1"style={{ outline: 'none', WebkitTapHighlightColor: 'transparent' }}>
          <h3>Total Staked</h3>
          <p>{totalStaked ? `${parseFloat(formatEther(totalStaked)).toFixed(2)} KONK` : '0 KONK'}</p>
          <span className="description">Total KONK in vault</span>
        </div>
        <div className="stat-box1">
          <h3>Your Stake</h3>
          <p>{stakedBalance ? `${parseFloat(formatEther(stakedBalance)).toFixed(2)} KONK` : '0 KONK'}</p>
          <span className="description">Your staked balance</span>
        </div>
        <div className="stat-box1">
          <h3>Your Balance</h3>
          <p>{konkBalance ? `${parseFloat(formatEther(konkBalance)).toFixed(2)} KONK` : '0 KONK'}</p>
          <span className="description">Available to stake</span>
        </div>
        <div className="stat-box1">
          <h3>APY</h3>
          <p>{apy.toFixed(2)}%</p>
          <span className="description">Annual yield</span>
        </div>
        <div className="stat-box1">
          <h3>Earned Rewards</h3>
          <p>{rewards.toFixed(4)} KONK</p>
          <span className="description">Auto-compounding</span>
        </div>
      </div>

      <div className="action-box">
        <div className="mode-toggle">
          <button 
            className={`mode-btn ${mode === 'stake' ? 'active' : ''}`}
            onClick={() => setMode('stake')}
          >
            Stake
          </button>
          <button 
            className={`mode-btn ${mode === 'unstake' ? 'active' : ''}`}
            onClick={() => setMode('unstake')}
          >
            Unstake
          </button>
        </div>

        <div className="input-group">
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder={`Amount to ${mode}`}
            className="amount-input"
          />
          <div className="percentage-buttons">
            <button onClick={() => handlePercentageClick(25)}>25%</button>
            <button onClick={() => handlePercentageClick(50)}>50%</button>
            <button onClick={() => handlePercentageClick(75)}>75%</button>
            <button onClick={() => handlePercentageClick(100)}>Max</button>
          </div>
        </div>

        <button 
            onClick={mode === 'stake' ? handleApproveAndStake : handleUnstake}
            disabled={!isConnected || !amount || !!currentAction}
            className="main-action-button"
            >
            {currentAction && <span className="button-loader" />}
            {currentAction ? (
                currentAction === 'approve' ? 'Approving...' : 
                currentAction === 'stake' ? 'Staking...' : 
                'Unstaking...'
            ) : mode === 'stake' ? (
                !isApproved ? 'Approve and Stake' : 'Stake KONK'
            ) : (
                'Unstake KONK'
            )}
            </button>
      </div>

      {notification.show && (
    <div className={`notification1 ${isError ? 'error' : isSuccess ? 'success' : ''} ${
        notification.isClosing ? 'closing' : ''
    }`}>
        <div className="notification-content1">
            <div>{notification.message}</div>
            {notification.hash && (
                <a
                    href={`https://blastscan.io/tx/${notification.hash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tx-link"
                >
                    View transaction
                </a>
            )}
        </div>
        <button 
            className="close-button"
            onClick={() => showNotification({ show: false })}
        >
            ×
        </button>
    </div>
)}
<div className="staking-footer">
    <img 
        src="/images/blastlogo.svg" 
        alt="Blast Logo" 
        className="footer-logo"
    />
    <span className="footer-text"></span>
</div>

    </div>
);
}

export default KONKStaking;