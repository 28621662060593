import React from 'react';

const UpdateIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3M21 3L15 9M21 3V9M21 3H15" 
      stroke="#ccef22" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </svg>
);

const UpdateNotification = ({ onUpdate }) => {
  return (
    <div className="update-notification">
      <div className="update-notification-content">
        <UpdateIcon />
        <span>Update Available</span>
        <button onClick={onUpdate} className="update-button">
          Update Now
        </button>
      </div>
    </div>
  );
};

export default UpdateNotification; 