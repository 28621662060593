import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@rainbow-me/rainbowkit/styles.css';
import './rainbowkit-override.css';
import { RainbowKitProvider, getDefaultConfig, darkTheme, DisclaimerComponent } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { WalletProvider } from './WalletContext';
import BoomTokenCreator from './components/boom/BoomTokenCreator';

import KonkLand from './KonkLand';
import KonkRun from './components/KonkRun';
import KonkHyperMining from './components/KonkHyperMining';
import KONKDrop from './components/KONKDrop';
import KONKStaking from './components/KONKStaking';
import KONKCasino from './components/casino/konkcasino';
import Feedback from './components/Feedback';
import SnakeGame from './components/SnakeGame';
// import RouletteGame from './components/casino/games/wheel/RouletteGame';
// import KonkWheel from './components/casino/games/wheel/KonkWheel';
// import NoLossLottery from './components/casino/games/lottery/NoLossLottery.js';
// import BubbleBurst from './components/casino/games/bubble/BubbleBurst.js';
import MaintenancePage from './components/sos';

const blastChain = {
  id: 81457,
  name: 'Blast',
  network: 'blast',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc.blast.io'] },
    public: { http: ['https://rpc.blast.io'] },
  },
  blockExplorers: {
    default: { name: 'BlastScan', url: 'https://blastscan.io' },
  },
};

const config = getDefaultConfig({
  appName: 'KONK App',
  projectId: '812fb5d36869c7b001c1237443d07b07',
  chains: [blastChain],
  ssr: true,
});

const queryClient = new QueryClient();

// Create a custom theme that matches KONK styling
const theme = darkTheme({
  accentColor: '#ccef22',
  accentColorForeground: '#11140C',
  borderRadius: 'small',
  fontStack: 'system',
  overlayBlur: 'small',
});

// Custom disclaimer for RainbowKit modal
const Disclaimer = ({ Text }) => (
  <Text>
    By connecting your wallet to KONK, you agree to the Terms of Service and acknowledge you have read and understand the privacy policy.
  </Text>
);

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={theme} appInfo={{
          appName: 'KONK App',
          disclaimer: Disclaimer,
        }}>
          <WalletProvider>
            <Router>
            <Routes>
              <Route path="/" element={<KonkLand />} />
              <Route path="/konk-run" element={<KonkRun />} />
              <Route path="/konk-hyper-mining" element={<KonkHyperMining />} />
              <Route path="/konk-drop" element={<KONKDrop />} />
              <Route path="/konk-staking" element={<KONKStaking />} />
              <Route path="/casino" element={<KONKCasino />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/snake" element={<SnakeGame />} />
              {/* <Route path="/casino/wheel" element={<KonkWheel />} />
              <Route path="/casino/bubble" element={<BubbleBurst />} />
              <Route path="/casino/lottery" element={<NoLossLottery />} /> */}
              {/* <Route path="/casino/wheel" element={<RouletteGame />} /> */}
              <Route path="/boom" element={<BoomTokenCreator />} />
            </Routes>
            </Router>
          </WalletProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;