import React, { useState, useEffect } from 'react';
import './AddToHomeScreenPrompt.css';

function AddToHomeScreenPrompt() {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    // Check if prompt has been shown this session
    const hasPromptBeenShown = sessionStorage.getItem('konk_pwa_prompt_shown');
    
    // Check if the app is running as installed PWA
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches || 
                         window.navigator.standalone === true;
    
    if (isStandalone || hasPromptBeenShown === 'true') {
      // Don't show if already installed or shown this session
      setShowPrompt(false);
    } else {
      // If not in standalone mode and not shown before, show the prompt after a short delay
      const timer = setTimeout(() => {
        setShowPrompt(true);
        // Mark as shown for this session
        sessionStorage.setItem('konk_pwa_prompt_shown', 'true');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setShowPrompt(false);
  };

  if (!showPrompt) return null;

  return (
    <div className="add-to-homescreen-prompt">
      <button onClick={handleClose} className="close-button">&times;</button>
      <h3>Install KONK App for easy access!</h3>
      <img src="/images/addtohs.png" alt="Add to Home Screen" className="addtohs-image" />
    </div>
  );
}

export default AddToHomeScreenPrompt;