import { Drawer } from 'vaul';
import React from 'react';

export default function FolderDrawer({ isOpen, onClose, folder, apps }) {
  return (
    <Drawer.Root open={isOpen} onOpenChange={onClose}>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40 z-[9999]" />
        <Drawer.Content className="bg-[#11140C] flex flex-col rounded-t-[20px] h-[85vh] fixed bottom-0 left-0 right-0 z-[10000] border-t border-[#ccef22]/30">
          <div className="p-4 rounded-t-[20px] flex-1">
            <div className="mx-auto max-w-md">
              <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-[#ccef22]/30 mb-8" />
              <Drawer.Title className="font-medium mb-4 text-[#ccef22] text-xl font-chakra-petch text-center">
                {folder?.name || "Folder"}
              </Drawer.Title>
              
              <div className="folder-modal-apps">
                {apps?.map((app, index) => (
                  <a
                    key={index}
                    href={app.url}
                    target={app.external ? "_blank" : "_self"}
                    rel={app.external ? "noopener noreferrer" : ""}
                    className="folder-app"
                    onClick={app.action ? (e) => {
                      e.preventDefault();
                      app.action();
                    } : undefined}
                  >
                    <div className="folder-app-icon">
                      {typeof app.icon === 'string' ? (
                        <img src={app.icon || app.fallbackIcon} alt={app.name} />
                      ) : (
                        app.icon
                      )}
                    </div>
                    <span className="folder-app-name">{app.name}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
} 