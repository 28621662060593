import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../KonkLand.css';

// Feedback Icon
const FeedbackIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" rx="15" fill="#ccef22"/>
    <rect x="15" y="15" width="30" height="24" rx="4" fill="#11140C"/>
    <path d="M24 45L28 39H32L36 45" fill="#11140C"/>
    <path d="M19 22H41" stroke="#ccef22" strokeWidth="2" strokeLinecap="round"/>
    <path d="M19 27H41" stroke="#ccef22" strokeWidth="2" strokeLinecap="round"/>
    <path d="M19 32H30" stroke="#ccef22" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

function Feedback() {
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [telegramHandle, setTelegramHandle] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false);
  const feedbackInputRef = useRef(null);

  // Focus on feedback input when component mounts
  useEffect(() => {
    if (feedbackInputRef.current) {
      feedbackInputRef.current.focus();
    }
  }, []);

  const handleSendFeedback = (e) => {
    e.preventDefault();
    if (!feedbackMessage.trim()) return;

    // Create FormData for the API request
    const formData = new FormData();
    formData.append('message', feedbackMessage);
    
    if (twitterHandle) {
      formData.append('twitter', twitterHandle);
    }
    
    if (telegramHandle) {
      formData.append('telegram', telegramHandle);
    }

    // Send the feedback to the server (mock implementation)
    // In a real app, you would use fetch or axios to send this to your backend
    setTimeout(() => {
      console.log('Feedback sent successfully');
      // For demo purposes, we'll just simulate a successful response
      setFeedbackSent(true);
      
      // Reset form after 3 seconds and close the modal
      setTimeout(() => {
        setFeedbackMessage('');
        setTwitterHandle('');
        setTelegramHandle('');
        setFeedbackSent(false);
      }, 3000);
    }, 1000);
  };

  return (
    <div className="feedback-page">
      <div className="feedback-header">
        <Link to="/" className="back-link">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 12H5" stroke="#11140C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 19L5 12L12 5" stroke="#11140C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </Link>
        <h3>KONK Feedback</h3>
        <div style={{ width: 24 }}></div> {/* Empty div for layout balance */}
      </div>

      {feedbackSent ? (
        <div className="feedback-success">
          <div className="feedback-success-icon">✓</div>
          <h3>Thank You!</h3>
          <p>Your feedback has been received.</p>
        </div>
      ) : (
        <form className="feedback-form" onSubmit={handleSendFeedback}>
          <div className="feedback-form-group">
            <label htmlFor="feedback-message">Your Feedback:</label>
            <textarea
              id="feedback-message"
              ref={feedbackInputRef}
              className="feedback-textarea"
              value={feedbackMessage}
              onChange={(e) => setFeedbackMessage(e.target.value)}
              rows="5"
              placeholder="Share your thoughts, suggestions, or bug reports..."
              required
            ></textarea>
          </div>
          
          <div className="feedback-form-group">
            <label htmlFor="twitter-handle">Twitter Handle (optional):</label>
            <input
              type="text"
              id="twitter-handle"
              className="feedback-input"
              value={twitterHandle}
              onChange={(e) => setTwitterHandle(e.target.value)}
              placeholder="@yourusername"
            />
          </div>
          
          <div className="feedback-form-group">
            <label htmlFor="telegram-handle">Telegram Handle (optional):</label>
            <input
              type="text"
              id="telegram-handle"
              className="feedback-input"
              value={telegramHandle}
              onChange={(e) => setTelegramHandle(e.target.value)}
              placeholder="@yourusername"
            />
          </div>
          
          <button type="submit" className="feedback-submit-btn">
            Send Feedback
          </button>
        </form>
      )}
    </div>
  );
}

export default Feedback; 